
swiper-container {
    --swiper-pagination-color: #9BD127;
    --swiper-pagination-bullet-inactive-color: white;
    --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
    --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
    --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
    display: flex;
    position: relative;

    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    font-size: 18px;

    text-align: center;
    box-sizing: border-box;
}

swiper-slide img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
}
